import { BrowserRouter, Route, Routes } from "react-router-dom";
import EndScreen from "./EndScreen";
import { NotFoundScreen } from "./NotFoundScreen";
import { Sumsub } from "./Sumsub";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:entityId" element={<Sumsub />} />
        <Route path="/endScreen" element={<EndScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
